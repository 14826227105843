import './Style.scss';
import Translate from './Translate';
import {CONFIG} from '../env';

function getButtonImage(name){
    let returnData = "";

    if(name == "previous"){
        returnData = <img src={CONFIG.gameAssetsDir + "images/left.svg"} />;        
    }else if(name == "next"){
        returnData = <img src={CONFIG.gameAssetsDir + "images/left.svg"} style={{transform: "rotate(180deg)"}} />;
    }else if(name == "submit"){
        returnData = <Translate trKey="submit" />;
    }else if(name == "hearAgain"){
        returnData = <img src={CONFIG.gameAssetsDir + "images/hear.svg"} style={{width: "50px"}} />
    }else if(name == "logo"){
        returnData = <img src={CONFIG.gameAssetsDir + "images/logoLandingPage.png"} />
    }else if(name == "userProfile"){
        returnData = <img src={CONFIG.gameAssetsDir + "images/userProfile.svg"} />
    }else if(name == "menu"){
        returnData = <img src={CONFIG.gameAssetsDir + "images/menu.svg"} />
    }else if(name == "home"){
        returnData = <div style={{border: "solid 1px #00000040", borderRadius: "45px", backgroundColor: "#FFFFFF50", fontSize: "22px", width: "45px", height: "45px", display: "flex", justifyContent: 'center', alignItems: "center"}}><img style={{width: "50%"}} src={CONFIG.gameAssetsDir + "images/homeIcon.png"} /></div>
    }else if(name == "test"){
        returnData = <div style={{border: "solid 1px #00000040", borderRadius: "45px", backgroundColor: "#FFFFFF50", fontSize: "22px", width: "45px", height: "45px", display: "flex", justifyContent: 'center', alignItems: "center"}}>प्र</div>
    }else{
        returnData = <div>B</div>;
    }
    return returnData;
}

function Botton(props){
    let touchStartHandler = (e)=>{
        let target = e.currentTarget;
        e.currentTarget.classList.add("hover");
        if(props.onTouchStart){
            props.onTouchStart();
        }
    }

    let touchEndHandler = (e)=>{
        let target = e.currentTarget;
        e.currentTarget.classList.remove("hover");
        if(props.onTouchEnd){
            props.onTouchEnd();
        }
    }

    let onClickHandler = (e)=>{
        if(e.currentTarget.classList.contains("disabled")){
            return;
        }
        if(props.onClick){
            props.onClick();
        }
    }
    
    return(
        <div className={"button grid acCenter jcCenter" + " " + (props.className?props.className:"")}
             style={props.style}
             onClick={onClickHandler}
             onTouchStart={touchStartHandler}
             onTouchEnd={touchEndHandler}>
            {getButtonImage(props.name)}
        </div>
    );
}

export default Botton;