import Translate from './Translate';

function ScoreBoard(props){
    
    return (
        <div className='scoreBoard'>
            <div className="currentScore">
                <div className="scoreDigit">{props.currentScore}</div>
                <div className="scoreTitle"><Translate trKey="game score" /></div>
            </div>    
            <div className="totalScore">
                <div className="scoreDigit">{props.totalScore}</div>
                <div className="scoreTitle"><Translate trKey="total score" /></div>
            </div>
        </div>
    );
}

export {ScoreBoard};