import './Style.scss';
import Translate from "../components/Translate";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import { CONFIG, ENV } from '../env';
import { useEffect, useState } from 'react';
import * as API from './../service';
import { AppStore, MasterData } from '../store';
import BhashaKhel from './BhashaKhel';


function Intro(props) {
    const [page, setPage] = useState("Intro");

    //Loading gameData
    useEffect(()=>{
        //
        API.getGameDataFromServer(CONFIG.API_URL).then((response)=>{
            if(ENV == "dev" || ENV == "local"){
                console.log("\n\n==============\nResponse:", response);
            }

            let userData = {
              class:response.data.class,
              email:response.data.email,
              groupCode:response.data.groupCode,
              id:response.data.id,
              language:response.data.language,
              name:response.data.name,
              profileImage:response.data.profileImage,
              score:response.data.score,
              subscription:response.data.subscription,
              type:response.data.type,
              year:response.data.year,
              test:response.data.test,
              testDuration:response.data.testDuration,
              timePerQuestion:response.data.timePerQuestion,
              todaysTestPlayed:response.data.todaysTestPlayed,
              nextPaheli: response.data.nextPaheli,
              playedScore: response.data.playedScore,
              currentQuesPHP: response.data.currentQuesPHP,
              totalQuesPHP: response.data.totalQuesPHP
            }
            MasterData.userData = userData;

            // MasterData.gameData.bhashaKhel = response.data.allQuestions;
            MasterData.gameData.bhashaKhel = response.data.questions;

            //
            initStore();
        });
        //
        // API.getGameData(CONFIG.gameAssetsDir + CONFIG.gameDataURL.bhashaKhel).then((response)=>{
        //     MasterData.gameData.bhashaKhel = response.data;
        //     initStore();
        // });

        // API.getGameData(CONFIG.gameAssetsDir + CONFIG.gameDataURL.bhashaTest).then((response)=>{
        //     MasterData.gameData.bhashaTest = response.data;
        //     initStore();
        // });

        // //
        // API.getGameData(CONFIG.gameAssetsDir + CONFIG.gameDataURL.ganitKhel).then((response)=>{
        //     MasterData.gameData.ganitKhel = response.data;
        //     initStore();
        // });

        // //
        // API.getGameData(CONFIG.gameAssetsDir + CONFIG.gameDataURL.ganitTest).then((response)=>{
        //     MasterData.gameData.ganitTest = response.data;
        //     initStore();
        // });
    }, []);

    const initStore = () => {
        if(MasterData.gameData.bhashaKhel
            //&& MasterData.gameData.ganitKhel && MasterData.gameData.ganitTest
            ){
            AppStore.initialized = true;
            if(ENV == "dev"){
                console.log("MasterData:", MasterData);
                console.log("AppStore:", AppStore);
            }
            updatePage("bhashaKhel");
        }
    }
    // console.log("Page:", page);

    const updatePage = (pageName) => {
        // console.log("Set page name to:", pageName);
        setPage(pageName);
    }

    if(page === "Intro"){
        return (
            <div className="page introPage">
                {/* <Header updatePage={updatePage} /> */}

                {/* <div className="pageContent">
                    <div className="grid" style={{alignContent:"space-evenly"}}>
                        <div className="pageTitle">
                            <Translate trKey="intro heading 1" />
                        </div>

                        <div className='flex'>
                            <div className="startButton" onClick={()=>{updatePage("BhashaKhel")}}>
                                <Translate trKey="Button start" />
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <Footer /> */}
            </div>
        );
    }else{
        return (<BhashaKhel dataKey="bhashaKhel"  updatePage={updatePage} />)
    }
}

export default Intro;
