import React from "react";
import {CONFIG} from '../env';

function Alert(props) {
    return (
        <div className="alertContainer" onClick={(props.type == "correct")?props.handlerOnCorrectAnswer:props.handlerOnWrongAnswer}>
            <div className={"backdrop" + " " + props.type}></div>
            <div className={"alertContent" + " " + props.type}>
                <div className={"contentContainer" + " " + props.type}>
                    {/* GIFS are downloaded from 
                    https://lottiefiles.com/search?q=Try%20again&category=animations */}
                    {(props.type == "correct")?(
                        <React.Fragment>
                            <span>शाबाश।!</span>
                            <img className="gifImage" src={CONFIG.gameAssetsDir + "images/emoji-cool.gif"} style={{width: "100%"}} />
                            <span className="message">{props.message}</span>
                        </React.Fragment>
                    ):(
                        <React.Fragment>
                            <span>दोबारा प्रयास करें!</span>
                            <img className="gifImage" src={CONFIG.gameAssetsDir + "images/emoji-no.gif"} style={{width: "100%"}} />
                            {/* <span className="message"><img src={CONFIG.gameAssetsDir + "images/smile4.svg"} /></span> */}
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Alert;