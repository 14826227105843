import {ENV, CONFIG} from './env';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Intro from './pages/Intro';
import initReactFastclick from 'react-fastclick';
import './i18n';
import Game from './pages/Game';
import BhashaKhel from './pages/BhashaKhel';
import GanitKhel from './pages/GanitKhel';
import NotAvailable from './pages/NotAvailable';
import GanitTest from './pages/GanitTest';
import BhashaTest from './pages/BhashaTest';

if(ENV == "dev"){
	console.warn("::RUNNING IN DEVELOPMENT MODE::");
	console.log("Environment:", ENV);
	console.log("Config:", CONFIG);
}

initReactFastclick();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter basename={"/"}>
		<Routes>
			<Route path="*" element={<Intro />} />
		</Routes>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
