import React from "react";
import {CONFIG} from '../env';

function AlertTestIntro(props) {
    return (
        <div className="alertContainer">
            <div className={"backdrop" + " " + "correct congrats"}></div>
            <div className="alertContent">
                <div className={"contentContainer" + " " + props.type} style={{backgroundColor: "#fff0"}}>
                    <React.Fragment>
                        <div>शनिवार की इंटरैक्टिव शिक्षा चुनौती।</div>
                        <div style={{fontWeight: "normal", marginTop:"15px"}}>
                            आप को पाँच प्रश्न मिलेंगे।
                            सही जवाब देने के लिए, {props.timePerQuestion} प्रति प्रश्न मिलेंगे।
                        </div>
                        <div style={{marginTop:"15px"}}>
                            "ध्यान दें! - आप इस चुनौती को केवल एक ही बार खेल सकते हैं।"
                        </div>
                        <span className="message">{props.testDuration} की चुनौती शुरू करें।</span>
                        <div className="button" onClick={props.handlerOnClick}>
                            शुरू
                        </div>
                    </React.Fragment>
                </div>
            </div>
        </div>
    );
}

export default AlertTestIntro;