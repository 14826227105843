// import './Style.scss';
// import Header from "../components/Header";
// import Footer from "../components/Footer";
// import Button from "../components/Button";
// import { AppStore, MasterData } from "../store";
// import { useNavigate } from "react-router-dom";
// import React, { useEffect, useRef, useState } from "react";
// import { i18n } from '../i18n';
// import { Template1, Template2, Template3, Template4 } from '../components/Templates';
// import Alert from '../components/Alert';
// import AlertReplay from '../components/AlertReplay';
// import Translate from '../components/Translate';

// function BhashaKhel(props) {

//     return (
//         <div className={"page gamePage" + " " + i18n.language}>
//             <Header testLink="/bhasha-test" />

//             <div>
//             </div>

//             <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
//                 <Translate trKey="No information" />

//             </div>

//             <Footer />
//         </div>
//     );
// }

// export default BhashaKhel;

import './Style.scss';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Button from "../components/Button";
import { AppStore, MasterData } from "../store";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { i18n } from '../i18n';
import { Template1, Template2, Template3, Template4, TemplateMCQ, TemplateJumbled, TemplateFIB, TemplateMTF } from '../components/Templates';
import Alert from '../components/Alert';
import AlertReplay from '../components/AlertReplay';
import AlertAlreadyPlayed from '../components/AlertAlreadyPlayed';
import { CONFIG } from '../env';
import * as API from './../service';
import AlertTestIntro from '../components/AlertTestIntro';

const GetScore = (_totalAttempts, _template) => {
    let score = 0;

    if (_template == "v_keyboard" || _template == "jumbled_words" || _template == "fib") {
        if (_totalAttempts == 0) {
            score = 4;
        }
    } else if (_template == "select_odd" || _template == "mcq") {
        if (_totalAttempts == 0) {
            score = 4;
        } else if (_totalAttempts == 1) {
            score = 3;
        } else if (_totalAttempts == 2) {
            score = 2;
        } else if (_totalAttempts == 3) {
            score = 1;
        }
    } else if (_template == "true_false") {
        if (_totalAttempts == 0) {
            score = 4;
        } else if (_totalAttempts == 1) {
            score = 2;
        }
    }

    // return score;
    return 10;
}

function BhashaKhel(props) {
    const [bonusAudio] = useState(new Audio("/interactive-learning/assets/sfx/bonus.mp3"));
    const [correctAudio] = useState(new Audio("/interactive-learning/assets/sfx/correct.mp3"));
    const [wrongAudio] = useState(new Audio("/interactive-learning/assets/sfx/wrong.mp3"));
    const [scoreAudio] = useState(new Audio("/interactive-learning/assets/sfx/score.mp3"));
    const [isMuted, setIsMuted] = useState(false);
    const refGamePage = useRef();
    const refAlertContainerRoot = useRef();
    const refTemplat1 = useRef();
    const refTemplat2 = useRef();
    const refTemplat3 = useRef();
    const refTemplat4 = useRef();
    const refTemplatMCQ = useRef();
    const refTemplatJumbled = useRef();
    const refTemplateFIB = useRef();
    const [alertType, setAlertType] = useState("none");
    const [showReplayAlert, setShowReplayAlert] = useState(false);
    const [showAlreadyPlayedAlert, setShowAlreadyPlayedAlert] = useState(false);
    const [showTestIntroAlert, setShowTestIntroAlert] = useState(MasterData.userData.test || false);
    const [submittedAnswer, setSubmittedAnswer] = useState(null);
    // const [submitDisabled, setSubmitDisabled] = useState(true);
    const [gameData, setGameData] = useState(MasterData.gameData[props.dataKey]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(AppStore.currentQuestionIndex);
    const [questionData, setQuestionData] = useState(null);
    const navigate = useNavigate();
    const [totalAttempt, setTotalAttempt] = useState(0);
    const [userScore, setUserScore] = useState({});
    // const [actualPlayedScore, setActualPlayedScore] = useState(Number(MasterData.userData.playedScore));
    const [playedScore, setPlayedScore] = useState(Number(MasterData.userData.playedScore));
    // tempUserScore[questionId] = GetScore(totalAttempt, questionData.template);
    const [totalScore, setTotalScore] = useState(MasterData.userData.score);
    const [replayingGame, setReplayingGame] = useState(false);

    const resetState = () => {
        if (refTemplat1.current) {
            refTemplat1.current.resetState();
        }
        if (refTemplat2.current) {
            refTemplat2.current.resetState();
        }
        if (refTemplat3.current) {
            refTemplat3.current.resetState();
        }
        if (refTemplatJumbled.current) {
            refTemplatJumbled.current.resetState();
        }
        if (refTemplatMCQ.current) {
            refTemplatMCQ.current.resetState();
        }
        if (refTemplateFIB.current) {
            refTemplateFIB.current.resetState();
        }
        // setSubmitDisabled(true);
    }

    const handlerPreviousQuestion = () => {
        if (AppStore.currentQuestionIndex > 0 && alertType == "none" && showReplayAlert == false && showTestIntroAlert == false) {
            AppStore.currentQuestionIndex--;
            setCurrentQuestionIndex(AppStore.currentQuestionIndex);
            setQuestionData(gameData[AppStore.currentQuestionIndex]);
            resetState();

            //
            setTotalAttempt(0);
        }
    }

    const handlerNextQuestion = () => {
        if (AppStore.currentQuestionIndex < gameData.length - 1 && alertType == "none" && showReplayAlert == false && showTestIntroAlert == false) {
            AppStore.currentQuestionIndex++;
            setCurrentQuestionIndex(AppStore.currentQuestionIndex);
            setQuestionData(gameData[AppStore.currentQuestionIndex]);
            resetState();

            //
            setTotalAttempt(0);
        }
    }

    const handlerCloseAlert = () => {
        resetState();
        setAlertType("none");
    }

    const setNoScoreForCurrentLevel = (_userScore) => {
        let tempUserScore = _userScore?JSON.parse(JSON.stringify(_userScore)):JSON.parse(JSON.stringify(userScore));
        let tempUserScoreToSave = JSON.parse(JSON.stringify(tempUserScore));
        for (let i = 0; i < gameData.length - 1; i++) {
            let questionId = gameData[i]["nid"];
            if (tempUserScoreToSave[questionId] == undefined) {
                // console.log("Setting 0 value for qid:", questionId)
                tempUserScoreToSave[questionId] = 0;
            }
        }
        // console.log("Save user score:", tempUserScoreToSave);

        //
        API.saveData(CONFIG.API_URL, Number(MasterData.userData.score), tempUserScoreToSave).then((response) => {
            console.log("User score saved successfully:", response.data);
        });

        // if(MasterData.userData.playedScore == 0){
            setUserScore(tempUserScoreToSave);
        // }
    }

    const playAudio = (_audioName) => {
        if(isMuted){
            //Do nothing
        }else{
            _audioName.play()
        }
    }

    const handlerSubmit = () => {
        if (alertType == "none" && showReplayAlert == false && showTestIntroAlert == false) {

            console.log("submittedAnswer:", submittedAnswer, questionData.answer);

            //Capture score
            let questionId = questionData.nid;
            let tempUserScore = JSON.parse(JSON.stringify(userScore));

            // console.log(totalAttempt, "questionData:", questionData);
            if (String(submittedAnswer) == questionData.answer) {                
                let currentScore = GetScore(totalAttempt, questionData.template);

                // if(playedScore == 0){
                    tempUserScore[questionId] = currentScore;
                    // if(currentScore > actualPlayedScore){
                        setUserScore(tempUserScore);
                    // }
                // }


                //
                playAudio(scoreAudio);
                
                //
                if (totalAttempt == 0) {
                    playAudio(bonusAudio);
                } else {
                    playAudio(correctAudio);
                }

                //
                if (AppStore.currentQuestionIndex < gameData.length - 1) {
                    if (MasterData.userData.test == true) {
                        handlerNextQuestion();
                    } else {
                        let popUpDelay = 3000;
                        if (totalAttempt == 0) {
                            popUpDelay = 4500;
                        }

                        setAlertType("correct");
                        setTimeout(() => {
                            handlerCloseAlert();
                            handlerNextQuestion();
                        }, popUpDelay);
                    }
                } else {
                    //
                    setNoScoreForCurrentLevel(tempUserScore);

                    //
                    setReplayingGame(false);

                    //
                    setShowReplayAlert(true);
                }
            }
            else {
                //
                playAudio(wrongAudio);
                //
                setTotalAttempt(totalAttempt + 1);
                if (MasterData.userData.test == true) {
                    if (AppStore.currentQuestionIndex < gameData.length - 1) {
                        handlerNextQuestion();
                    } else {
                        //
                        setNoScoreForCurrentLevel();

                        //
                        setShowReplayAlert(true);
                    }
                } else {
                    setAlertType("wrong");
                    setTimeout(() => {
                        handlerCloseAlert();
                    }, 2700);
                }
            }
            console.log("tempUserScore:", tempUserScore);
        }
    }

    const handlerReplay = () => {
        AppStore.currentQuestionIndex = 0;
        setCurrentQuestionIndex(AppStore.currentQuestionIndex);
        setQuestionData(gameData[AppStore.currentQuestionIndex]);
        handlerCloseAlert();
        setShowReplayAlert(false);
        // setUserScore({});
        window.open(MasterData.userData.nextPaheli, '_self');
    }

    const handlerResetAndTryAgain = () => {
        console.log("Reset and try again");
        resetState();
        setReplayingGame(true);
        // setPlayedScore(0);
        setShowAlreadyPlayedAlert(false);
    }

    const handlerStartTest = () => {
        setShowTestIntroAlert(false);
    }

    const showUserScore = () => {
        if(replayingGame == true){
            return Object.values(userScore).reduce((a, i) => { return a + i }, 0);
        }else{
            if(playedScore == 0){
                return Object.values(userScore).reduce((a, i) => { return a + i }, 0);
            }else{
                return playedScore;
            }
        }
    }

    const handlerMute = () => {
        if(isMuted){
            setIsMuted(false);
        }else{
            setIsMuted(true);
        }
    }

    useEffect(() => {
        if (!AppStore.initialized) {
            navigate("/");
        } else {
            AppStore.currentQuestionIndex = 0;
            setCurrentQuestionIndex(AppStore.currentQuestionIndex);
            setQuestionData(gameData[AppStore.currentQuestionIndex]);
        }
    }, [gameData]);

    useEffect(() => {
        MasterData.AppStore = userScore;
        if(MasterData.userData.playedScore == 0){
            //Accumulate previous total score with current game played score
            setTotalScore(Number(MasterData.userData.score) + Number(Object.values(userScore).reduce((a, i) => { return a + i }, 0)));
        }else{
            //Do not accumulate totalScore if user have already played the game i.e. if playedScore != 0
            setTotalScore(Number(MasterData.userData.score));
        }
        // console.log("User score:", MasterData.AppStore);
    }, [userScore, MasterData.userData.score]);

    useEffect(() => {
        if (playedScore == 0) {
            setShowAlreadyPlayedAlert(false);
        }else{
            setShowAlreadyPlayedAlert(true);
        }
    }, [playedScore]);
    
    // console.log("questionData:", questionData);
    if (!gameData || !questionData) { return; }
    // console.log("GameData:", gameData);
    // console.log("MasterData:", MasterData);
    // console.log("showTestIntroAlert:", showTestIntroAlert);
    return (
        <div className={"page gamePage" + " " + i18n.language} ref={refGamePage}>

            {(MasterData.userData.test == true && MasterData.userData.todaysTestPlayed == true) ?
                (
                    <div id="paheli_container" style={{ minHeight: "300px", display: "flex", justifyContent: "center" }}>
                        <div style={{ textAlign: "center", lineHeight: "28px" }}>
                            <span style={{ fontWeight: "bold", fontSize: "20px", lineHeight: "45px" }}>क्षमा करें।</span>
                            <br />
                            नियमानुसार, आप शनिवार की इंटरैक्टिव चुनौती केवल एक ही बार खेल सकते हैं।
                            <br />
                            आप इस चुनौती को खेलने का मौका प्रयोग कर चुके हैं।
                            अगली चुनौती, अगले शनिवार।
                        </div>
                    </div>
                )
                :
                (
                    <div id='paheli_container'>

                        {(questionData.template == "v_keyboard") ? <Template1 ref={refTemplat1}
                            handlerMute={handlerMute}
                            isMuted={isMuted}
                            handlerPreviousQuestion={handlerPreviousQuestion}
                            handlerNextQuestion={handlerNextQuestion}
                            handlerSubmit={handlerSubmit}
                            currentQuestion={currentQuestionIndex + 1}
                            totalQuestions={gameData.length}
                            // setSubmitDisabledState={setSubmitDisabled}
                            data={questionData}
                            setSubmittedAnswer={setSubmittedAnswer}
                            totalScore={totalScore}
                            userScore={showUserScore()} /> : ""}
                        {(questionData.template == "select_odd") ? <Template2 ref={refTemplat2}
                            handlerMute={handlerMute}
                            isMuted={isMuted}
                            handlerPreviousQuestion={handlerPreviousQuestion}
                            handlerNextQuestion={handlerNextQuestion}
                            handlerSubmit={handlerSubmit}
                            currentQuestion={currentQuestionIndex + 1}
                            totalQuestions={gameData.length}
                            // setSubmitDisabledState={setSubmitDisabled}
                            data={questionData}
                            setSubmittedAnswer={setSubmittedAnswer}
                            totalScore={totalScore}
                            userScore={showUserScore()} /> : ""}
                        {(questionData.template == "true_false") ? <Template3 ref={refTemplat3}
                            handlerMute={handlerMute}
                            isMuted={isMuted}
                            handlerPreviousQuestion={handlerPreviousQuestion}
                            handlerNextQuestion={handlerNextQuestion}
                            handlerSubmit={handlerSubmit}
                            currentQuestion={currentQuestionIndex + 1}
                            totalQuestions={gameData.length}
                            // setSubmitDisabledState={setSubmitDisabled}
                            data={questionData}
                            setSubmittedAnswer={setSubmittedAnswer}
                            totalScore={totalScore}
                            userScore={showUserScore()} /> : ""}
                        {(questionData.template == 4) ? <Template4 ref={refTemplat4}
                            handlerMute={handlerMute}
                            isMuted={isMuted}
                            handlerPreviousQuestion={handlerPreviousQuestion}
                            handlerNextQuestion={handlerNextQuestion}
                            currentQuestion={currentQuestionIndex + 1}
                            totalQuestions={gameData.length}
                            // setSubmitDisabledState={setSubmitDisabled}
                            data={questionData}
                            setSubmittedAnswer={setSubmittedAnswer}
                            totalScore={totalScore}
                            userScore={showUserScore()} /> : ""}
                        {(questionData.template == "mcq") ? <TemplateMCQ ref={refTemplatMCQ}
                            handlerMute={handlerMute}
                            isMuted={isMuted}
                            handlerPreviousQuestion={handlerPreviousQuestion}
                            handlerNextQuestion={handlerNextQuestion}
                            handlerSubmit={handlerSubmit}
                            currentQuestion={currentQuestionIndex + 1}
                            totalQuestions={gameData.length}
                            // setSubmitDisabledState={setSubmitDisabled}
                            data={questionData}
                            setSubmittedAnswer={setSubmittedAnswer}
                            totalScore={totalScore}
                            userScore={showUserScore()} /> : ""}
                        {(questionData.template == "jumbled_words") ? <TemplateJumbled ref={refTemplatJumbled}
                            handlerMute={handlerMute}
                            isMuted={isMuted}
                            handlerPreviousQuestion={handlerPreviousQuestion}
                            handlerNextQuestion={handlerNextQuestion}
                            handlerSubmit={handlerSubmit}
                            currentQuestion={currentQuestionIndex + 1}
                            totalQuestions={gameData.length}
                            // setSubmitDisabledState={setSubmitDisabled}
                            data={questionData}
                            setSubmittedAnswer={setSubmittedAnswer}
                            totalScore={totalScore}
                            userScore={showUserScore()} /> : ""}
                        {(questionData.template == "fib") ? <TemplateFIB ref={refTemplateFIB}
                            handlerMute={handlerMute}
                            isMuted={isMuted}
                            handlerPreviousQuestion={handlerPreviousQuestion}
                            handlerNextQuestion={handlerNextQuestion}
                            handlerSubmit={handlerSubmit}
                            currentQuestion={currentQuestionIndex + 1}
                            totalQuestions={gameData.length}
                            // setSubmitDisabledState={setSubmitDisabled}
                            data={questionData}
                            setSubmittedAnswer={setSubmittedAnswer}
                            totalScore={totalScore}
                            userScore={showUserScore()} /> : ""}
                        {(questionData.template == "mtf") ? <TemplateMTF ref={refTemplateFIB}
                            handlerMute={handlerMute}
                            isMuted={isMuted}
                            handlerPreviousQuestion={handlerPreviousQuestion}
                            handlerNextQuestion={handlerNextQuestion}
                            handlerSubmit={handlerSubmit}
                            currentQuestion={currentQuestionIndex + 1}
                            totalQuestions={gameData.length}
                            // setSubmitDisabledState={setSubmitDisabled}
                            data={questionData}
                            setSubmittedAnswer={setSubmittedAnswer}
                            totalScore={totalScore}
                            userScore={showUserScore()} /> : ""}

                        {/* <Button name="submit" className={"submitButton" + " " + (submitDisabled?"disabled":"")} onClick={handlerSubmit} /> */}

                        {/* <Footer /> */}
                        {(alertType != "none") ? (
                            <Alert type={alertType}
                                message={questionData.message}
                            // handlerOnCorrectAnswer={()=>{handlerCloseAlert(); handlerNextQuestion();}}
                            // handlerOnWrongAnswer={handlerCloseAlert}
                            />
                        ) : ("")}

                        {(showReplayAlert == true) ? (
                            <AlertReplay
                                handlerOnClick={handlerReplay}
                                score={showUserScore()}
                                replayButton={MasterData.userData.test ? false : true}
                                closeButton={MasterData.userData.test ? true : false} />
                        ) : ("")}

                        {(showAlreadyPlayedAlert == true) ? (
                            <AlertAlreadyPlayed
                                handlerOnClick={handlerReplay}
                                handlerOnTryAgain={handlerResetAndTryAgain}
                                score={showUserScore()}
                                replayButton={MasterData.userData.test ? false : true}
                                tryAgainButton={true}
                                closeButton={MasterData.userData.test ? true : false} />
                        ) : ("")}

                        {(showTestIntroAlert == true) &&
                            <AlertTestIntro
                                handlerOnClick={handlerStartTest}
                                timePerQuestion={MasterData.userData.timePerQuestion}
                                testDuration={MasterData.userData.testDuration} />
                        }
                    </div>
                )
            }

        </div>
    );
}

export default BhashaKhel;
