import React, { useEffect, useRef, forwardRef, useImperativeHandle, useState } from "react";
import { CONFIG } from "../env";
import CorrectOrWrong from "./CorrectOrWrong";
import Translate from "./Translate";
import { VirtualKeyboard, VirtualKeyboard2, VirtualKeyboard3, VirtualKeyboardSubmit, VirtualKeyboardSubmitAndClear } from "./VirtualKeyboard";
import { ScoreBoard } from "./ScoreBoard";
import Button from "./Button";
import { AppStore, MasterData } from "../store";
// import { Stage, Sprite, Text } from "@inlet/react-pixi";
import icon from "./../icon.png";
// import { TextStyle } from "pixi.js";
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

function SubHeader(props){
    return (
        <React.Fragment>
            <div className="navigationPanel">
                <Button name="previous" className="previousButton" onClick={props.handlerPreviousQuestion} /><span>पिछला</span>
                <div className='questionNumber'>
                    <Translate trKey="question" />- {props.currentQuestion + "/" + props.totalQuestions}
                </div>
                <span>अगला</span><Button name="next" className="nextButton" onClick={props.handlerNextQuestion} />
            </div>
        </React.Fragment>
    );
}

function shuffleArray(_arr){
    let tempArr = JSON.parse(JSON.stringify(_arr));
    let returnData = [];
    let arrLength = tempArr.length;
    for(var i=0; i<arrLength; i++){
        let randomIndex = Math.floor(Math.random() * tempArr.length); 
        returnData.push(tempArr[randomIndex]);
        tempArr.splice(randomIndex, 1);
    }
    return returnData;
}

const Template1 = forwardRef((props, ref) => {
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [inputField, setInputField] = useState("");
    const [questionTextSplitted, setQuestionTextSplitted] = useState(String(props.data.questionText).split(","));
    

    const handlerOnVirtualKeyboardEvent = (keyPressed) => {
        // console.log("keyPressed:", keyPressed);
        if(keyPressed == "clear"){
            setInputField("");
        }else if(keyPressed == "undo"){
            if(inputField.length > 0){
                setInputField(inputField.substring(0, inputField.length-1));
            }
        }else{
            setInputField(inputField + keyPressed);
        }
    }

    useEffect(()=>{
        props.setSubmittedAnswer(inputField);
        
        if(inputField.length > 0){
            setSubmitDisabled(false);
        }else{
            setSubmitDisabled(true);
        }
    },[inputField])

    useEffect(()=>{
        setQuestionTextSplitted(String(props.data.questionText).split(","));
    }, [props.data.questionText])

    useImperativeHandle(ref, () => ({
        resetState() {
            // console.log("Resetting Templat1 state.");
            setInputField("");
        }
    }));

    return (
        <React.Fragment>
            <SubHeader  handlerPreviousQuestion={props.handlerPreviousQuestion}
                        handlerNextQuestion={props.handlerNextQuestion}
                        currentQuestion={props.currentQuestion} 
                        totalQuestions={props.totalQuestions} />

            <div className='questionArea'>
                <div className='mainQuestion' dangerouslySetInnerHTML={{__html: props.data.questionTitle}}></div>

                {props.data.questionImage && typeof(props.data.questionImage) == "string" && props.data.questionImage != "" ?(
                    <div className='mainQuestionImage'>
                        <img src={CONFIG.gameAssetsDir + CONFIG.gameAssetsURL + props.data.questionImage} />
                    </div>
                ):""}

                {props.data.questionText?(
                    <div className='mainQuestionText'>
                        {/* {props.data.questionText} */}
                        {questionTextSplitted.map((option, index) => (
                            <span className="word" key={index}>
                                {option}
                            </span>
                        ))}
                    </div>
                ):""}
                
                {/* <div className='mainQuestion bottom'>
                    {props.data.subQuestion}
                </div> */}
                {(props.data.instruction)?(
                    <div className='instructionArea'>
                        <div className='instructions'>
                            <Translate trKey="instructions" />
                        </div>

                        <div className='instructionsContent'>
                            {props.data.instruction}
                        </div>
                        <div className="quesNum">
                            {MasterData.userData.currentQuesPHP}/{MasterData.userData.totalQuesPHP}
                        </div>
                        <div className={"audioControl" + " " + (props.isMuted?"":"soundSwitch")} onClick={props.handlerMute}></div>
                    </div>
                ):""}
            </div>

            <div className="inputArea">
                <div className='inputFieldContainer'>
                    {inputField}
                </div>
                
                <VirtualKeyboard submitDisabled={submitDisabled} keyboardEvent={handlerOnVirtualKeyboardEvent} handlerSubmit={props.handlerSubmit} />
                <ScoreBoard totalScore={props.totalScore} currentScore={props.userScore} />
            </div>

        </React.Fragment>
    );
});

const Template2 = forwardRef((props, ref) => {
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const resetTemplate = () => {
        let optionsList = document.querySelectorAll(".options");
        optionsList.forEach(element => {
            element.classList.remove("active");
        });
        setSubmitDisabled(true);
    }

    useImperativeHandle(ref, () => ({
        resetState() {
            // console.log("Resetting Templat2 state.");
            resetTemplate();
        }
    }));

    const handlerSelectOption = (e, _optionIndex) => {
        let selectedOption = e.currentTarget;
        let isActiveAlready = selectedOption.classList.contains('active');
        let optionsList = document.querySelectorAll(".options");
        optionsList.forEach(element => {
            element.classList.remove("active");
        });
        if(!isActiveAlready){
            selectedOption.classList.add("active");
            setSubmitDisabled(false);
            props.setSubmittedAnswer(_optionIndex+1);
        }else{
            setSubmitDisabled(true);
            props.setSubmittedAnswer(null);
        }
    }

    const handlerOnVirtualKeyboardEvent = (keyPressed) => {
        // console.log("keyPressed:", keyPressed);
        if(keyPressed == "clear"){
            resetTemplate();
        }else if(keyPressed == "undo"){
            // Do nothing
        }else{
            // Do nothing
        }
    }

    return (
        <React.Fragment>

            <SubHeader  handlerPreviousQuestion={props.handlerPreviousQuestion}
                        handlerNextQuestion={props.handlerNextQuestion}
                        currentQuestion={props.currentQuestion} 
                        totalQuestions={props.totalQuestions} />

            <div className='template2 questionArea'>
                <div className='mainQuestion' dangerouslySetInnerHTML={{__html: props.data.questionTitle}}></div>

                <div className="highlightOption">
                    {props.data.options.map((image, index) => (
                        <div key={index} className="options" onClick={(e)=>{handlerSelectOption(e, index)}}>
                            <img src={CONFIG.gameAssetsDir + CONFIG.gameAssetsURL + image} />
                        </div>
                    ))}
                </div>
                {(props.data.instruction)?(
                    <div className='instructionArea'>
                        <div className='instructions'>
                            <Translate trKey="instructions" />
                        </div>

                        <div className='instructionsContent'>
                            {props.data.instruction}
                        </div>
                        <div className="quesNum">
                            {MasterData.userData.currentQuesPHP}/{MasterData.userData.totalQuesPHP}
                        </div>
                        <div className={"audioControl" + " " + (props.isMuted?"":"soundSwitch")} onClick={props.handlerMute}></div>
                    </div>
                ):""}
            </div>

            <div className="inputArea">
                <VirtualKeyboard2 submitDisabled={submitDisabled} keyboardEvent={handlerOnVirtualKeyboardEvent} handlerSubmit={props.handlerSubmit} />
                <ScoreBoard totalScore={props.totalScore} currentScore={props.userScore} />
            </div>
        </React.Fragment>
    );
});

const Template3 = forwardRef((props, ref) => {
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [selectedButton, setSelectedButton] = useState(null);

    useImperativeHandle(ref, () => ({
        resetState() {
            // console.log("Resetting Templat3 state.");
            setSubmitDisabled(true);
            setSelectedButton(null);
        }
    }));

    const handlerOnVirtualKeyboardEvent = (keyPressed) => {
        // console.log("keyPressed:", keyPressed);
        if(keyPressed == "1"){
            setSubmitDisabled(false);
            props.setSubmittedAnswer(1);
            setSelectedButton(1);
        }else if(keyPressed == "0"){
            setSubmitDisabled(false);
            props.setSubmittedAnswer(0);
            setSelectedButton(0);
        }else if(keyPressed == "clear"){
            setSubmitDisabled(true);
            props.setSubmittedAnswer(null);
            setSelectedButton(null);
        }else if(keyPressed == "undo"){
            // Do nothing
            setSelectedButton(null);
        }else{
            // Do nothing
            setSelectedButton(null);
        }
    }

    return (
        <React.Fragment>            
            <SubHeader  handlerPreviousQuestion={props.handlerPreviousQuestion}
                        handlerNextQuestion={props.handlerNextQuestion}
                        currentQuestion={props.currentQuestion} 
                        totalQuestions={props.totalQuestions} />

            <div className='template3 questionArea'>
                <div className='mainQuestion' dangerouslySetInnerHTML={{__html: props.data.questionTitle}}></div>

                {props.data.questionImage && typeof(props.data.questionImage) == "string" && props.data.questionImage != "" ?(
                    <div className='mainQuestionImage'>
                        <img src={CONFIG.gameAssetsDir + CONFIG.gameAssetsURL + props.data.questionImage} />
                    </div>
                ):""}

                {props.data.questionText?(
                    <div className='mainQuestionText'>
                        {props.data.questionText}
                    </div>
                    ):""}
                {(props.data.instruction)?(
                    <div className='instructionArea'>
                        <div className='instructions'>
                            <Translate trKey="instructions" />
                        </div>

                        <div className='instructionsContent'>
                            {props.data.instruction}
                        </div>
                        <div className="quesNum">
                            {MasterData.userData.currentQuesPHP}/{MasterData.userData.totalQuesPHP}
                        </div>
                        <div className={"audioControl" + " " + (props.isMuted?"":"soundSwitch")} onClick={props.handlerMute}></div>
                    </div>
                ):""}
            </div>
                
            <div className="inputArea">
                <VirtualKeyboard3 submitDisabled={submitDisabled} keyboardEvent={handlerOnVirtualKeyboardEvent} handlerSubmit={props.handlerSubmit} activeButton={selectedButton} />
                <ScoreBoard totalScore={props.totalScore} currentScore={props.userScore} />
            </div>
        </React.Fragment>
    );
});

const Template4 = forwardRef((props, ref) => {
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const refCanvasContainer = useRef();
    const [imageDim, setImageDimension] = useState(0);
    const [canvas, setCanvas] = useState(null);
    const [canvasContext, setCanvasContext] = useState(null);
    const [column1, column2] = props.data.optionImages;    

    useImperativeHandle(ref, () => ({
        resetState() {
            // console.log("Resetting Templat4 state.");
            setSubmitDisabled(true);
        }
    }));

    useEffect(()=>{
        let canvasContainerWidth = refCanvasContainer.current.clientWidth;
        let canvasContainerHeight = refCanvasContainer.current.clientHeight;
        let canvasWidth = canvasContainerWidth - 2;
        let canvasHeight = canvasContainerHeight - 10;
        let imageDim = canvasHeight / (column1.length + 1);
        setImageDimension(imageDim);

        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        canvas.width  = canvasWidth;
        canvas.height = canvasHeight;
        refCanvasContainer.current.innerHTML = "";
        refCanvasContainer.current.appendChild(canvas)
        setCanvas(canvas);
        setCanvasContext(ctx);

        console.log("canvasContainerWidth:", canvasContainerWidth); 
        console.log("canvasContainerHeight:", canvasContainerHeight);        
    },[refCanvasContainer])

    useEffect(()=>{
        console.log("Drawing effect:", canvasContext);
        if(!canvasContext){return;}
        for(var i=0; i<column1.length; i++){
            var image = new Image();
            image.src = CONFIG.BASE_URL + process.env.PUBLIC_URL + "/" + CONFIG.gameAssetsURL + column1[i];
            image.onload = () => {
                canvasContext.drawImage(image, 0, i*imageDim, imageDim, imageDim);
            };
        }
        // const image = new Image();
        // image.src = CONFIG.BASE_URL + process.env.PUBLIC_URL + "/" + CONFIG.gameAssetsURL + column1[0];
        // image.onload = () => {
        //     ctx.drawImage(image, 0, 0, 100, 100);
        // };
        
    },[canvas, canvasContext])

    return (
        <React.Fragment>            
            <SubHeader  handlerPreviousQuestion={props.handlerPreviousQuestion}
                        handlerNextQuestion={props.handlerNextQuestion}
                        currentQuestion={props.currentQuestion} 
                        totalQuestions={props.totalQuestions} />

            <div className='template4 questionArea'>
                <div className='mainQuestion' dangerouslySetInnerHTML={{__html: props.data.questionTitle}}></div>
                <div ref={refCanvasContainer} className='mainQuestionImage'>
                    {/* <canvas ref={refCanvas} id="canvas" width={345} height={436}></canvas> */}
                </div>                
            </div>
        </React.Fragment>
    );
});

const TemplateMCQ = forwardRef((props, ref) => {
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [checkedValue, setCheckedValue] = useState([false, false, false, false]);
    const resetTemplate = () => {
        setSubmitDisabled(true);
        setCheckedValue([false, false, false, false]);
    }

    useImperativeHandle(ref, () => ({
        resetState() {
            // console.log("Resetting Templat2 state.");
            resetTemplate();
        }
    }));

    const handlerSelectOption = (e, _optionIndex) => {
        // console.log("_optionIndex:", _optionIndex);
        let tempCheckedValue = JSON.parse(JSON.stringify(checkedValue));
        tempCheckedValue[_optionIndex] = true;
        setCheckedValue(tempCheckedValue);

        //
        setSubmitDisabled(false);
        props.setSubmittedAnswer(_optionIndex+1);
    }

    const handlerOnVirtualKeyboardEvent = (keyPressed) => {
        // console.log("keyPressed:", keyPressed);
        if(keyPressed == "clear"){
            resetTemplate();
        }else if(keyPressed == "undo"){
            // Do nothing
        }else{
            // Do nothing
        }
    }

    const handlerSubmit = () => {
        if(submitDisabled){return;}
        props.handlerSubmit();
    }

    return (
        <React.Fragment>            
            <SubHeader  handlerPreviousQuestion={props.handlerPreviousQuestion}
                        handlerNextQuestion={props.handlerNextQuestion}
                        currentQuestion={props.currentQuestion} 
                        totalQuestions={props.totalQuestions} />
            
            <div className='templateMCQ questionArea'>
                    <div className='mainQuestion' dangerouslySetInnerHTML={{__html: props.data.questionTitle}}></div>
                    
                    <div className='mainQuestionImage'>
                        {props.data.questionImage && typeof(props.data.questionImage) == "string" && props.data.questionImage != "" ?
                            <img src={CONFIG.gameAssetsDir + CONFIG.gameAssetsURL + props.data.questionImage} />
                        :""}
                    </div>
                    <div className="optionsContainer">
                        <ul>
                            {props.data.options.map((option, index) => (
                                <li key={index}>
                                    <input type="radio" className={"radio" + (" radioKey"+index)} value={option} name="mcq" checked={checkedValue[index]} onChange={(e)=>{handlerSelectOption(e, index)}} />
                                    <span>{option}</span>
                                </li>
                            ))}                            
                        </ul>
                    </div>
                {(props.data.instruction)?(
                    <div className='instructionArea'>
                        <div className='instructions'>
                            <Translate trKey="instructions" />
                        </div>

                        <div className='instructionsContent'>
                            {props.data.instruction}
                        </div>
                        <div className="quesNum">
                            {MasterData.userData.currentQuesPHP}/{MasterData.userData.totalQuesPHP}
                        </div>
                        <div className={"audioControl" + " " + (props.isMuted?"":"soundSwitch")} onClick={props.handlerMute}></div>
                    </div>
                ):""}
            </div>
                
            <div className="inputArea vkS-input">
                <VirtualKeyboardSubmit submitDisabled={submitDisabled} keyboardEvent={handlerOnVirtualKeyboardEvent} handlerSubmit={handlerSubmit} />
                <ScoreBoard totalScore={props.totalScore} currentScore={props.userScore} />
            </div>
        </React.Fragment>
    );
});

const TemplateJumbled = forwardRef((props, ref) => {
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [shuffledSequence, setShuffledSequence] = useState([]);
    const [wordSequence, setWordSequence] = useState([]);
    const [answerSequence, setAnswerSequence] = useState([]);
    // const [wordIndexSelected, setWordIndexSelected] = useState(-1);
    
    const resetTemplate = () => {
        setWordSequence(JSON.parse(JSON.stringify(shuffledSequence)));
        let answerSeq = String(props.data.answer).split(" ");
        let firstWord = answerSeq[0];
        setAnswerSequence([firstWord]);
        setSubmitDisabled(true);
    }

    useImperativeHandle(ref, () => ({
        resetState() {
            // console.log("Resetting TemplateJumbled state.");
            resetTemplate();
        }
    }));

    // const handlerOnSelectWord = (_wordIndex) => {
    //     // console.log("_wordIndex:", _wordIndex);
    //     if(wordIndexSelected == -1){
    //         setWordIndexSelected(_wordIndex);
    //     }else{
    //         swapWords(wordIndexSelected, _wordIndex);
    //         setWordIndexSelected(-1);
    //         setSubmitDisabled(false);
    //     }
    // }

    // const swapWords = (_wordIndexFrom, _wordIndexWith) => {
    //     // console.log("wordSequence:", wordSequence);
    //     let orgOrder = JSON.parse(JSON.stringify(wordSequence));
    //     let newOrder = JSON.parse(JSON.stringify(wordSequence));
    //     newOrder[_wordIndexFrom] = orgOrder[_wordIndexWith];
    //     newOrder[_wordIndexWith] = orgOrder[_wordIndexFrom];
    //     // console.log("orgOrder:", orgOrder, "newOrder:", newOrder);
    //     setWordSequence(newOrder);
    // }

    const handlerSendToAnswerQueue = (_wordIndex) => {
        //
        let tempAnswerSequence = JSON.parse(JSON.stringify(answerSequence));
        tempAnswerSequence.push(wordSequence[_wordIndex]);
        setAnswerSequence(tempAnswerSequence);
        
        //
        let tempWordSequence = JSON.parse(JSON.stringify(wordSequence));
        tempWordSequence.splice(_wordIndex, 1);
        setWordSequence(tempWordSequence);
    }

    const handlerSendToQuestionQueue = (_wordIndex) => { 
        if(_wordIndex === 0){return;}       
        //
        let tempWordSequence = JSON.parse(JSON.stringify(wordSequence));
        tempWordSequence.push(answerSequence[_wordIndex]);
        setWordSequence(tempWordSequence);

        //
        let tempAnswerSequence = JSON.parse(JSON.stringify(answerSequence));
        tempAnswerSequence.splice(_wordIndex, 1);
        setAnswerSequence(tempAnswerSequence);
    }

    useEffect(()=>{
        let answerSeq = String(props.data.answer).split(" ");
        let firstWord = answerSeq[0]
        answerSeq.shift();
        let jumbledSeq = shuffleArray(answerSeq);
        setShuffledSequence(JSON.parse(JSON.stringify(jumbledSeq)));
        setWordSequence(jumbledSeq);
        setAnswerSequence([firstWord]);
    }, [props.data.answer])
    
    useEffect(()=>{
        props.setSubmittedAnswer(answerSequence.join(" "));
    },[wordSequence])
    
    useEffect(()=>{
        if(answerSequence.length === 0){
            setSubmitDisabled(true);
        }else{
            setSubmitDisabled(false);
        }
    },[answerSequence])


    return (
        <React.Fragment>            
            <SubHeader  handlerPreviousQuestion={props.handlerPreviousQuestion}
                        handlerNextQuestion={props.handlerNextQuestion}
                        currentQuestion={props.currentQuestion} 
                        totalQuestions={props.totalQuestions} />
            
            <div className='templateJumbled questionArea'>
                <div>
                    <div className='mainQuestion' dangerouslySetInnerHTML={{__html: props.data.questionTitle}}></div>
                    <div className='mainQuestionImage'>
                        {props.data.questionImage && typeof(props.data.questionImage) == "string" && props.data.questionImage != "" ?
                            <img src={CONFIG.gameAssetsDir + CONFIG.gameAssetsURL + props.data.questionImage} />
                        :""}
                    </div>
                    <div className="optionsContainer">
                        {wordSequence.map((option, index) => (
                            <span className="word" key={index} onClick={()=>{handlerSendToAnswerQueue(index)}}>
                                {option}
                            </span>
                        ))}  
                    </div>
                    <div className="answerContainer">
                        {answerSequence.map((option, index) => (
                            <span className="word" key={index} onClick={()=>{handlerSendToQuestionQueue(index)}}>
                                {option}
                            </span>
                        ))}  
                    </div>
                </div>
                    {(props.data.instruction)?(
                        <div className='instructionArea'>
                            <div className='instructions'>
                                <Translate trKey="instructions" />
                            </div>

                            <div className='instructionsContent'>
                                {props.data.instruction}
                            </div>
                            <div className="quesNum">
                                {MasterData.userData.currentQuesPHP}/{MasterData.userData.totalQuesPHP}
                            </div>
                            <div className={"audioControl" + " " + (props.isMuted?"":"soundSwitch")} onClick={props.handlerMute}></div>
                        </div>
                    ):""}
            </div>
                
                <div className="inputArea vkS-input">
                    <VirtualKeyboardSubmit submitDisabled={submitDisabled} handlerSubmit={props.handlerSubmit} />
                    <ScoreBoard totalScore={props.totalScore} currentScore={props.userScore} />
                </div>
        </React.Fragment>
    );
});

const TemplateFIB = forwardRef((props, ref) => {
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const refMainQuestion = useRef();
    const [question, setQuestion] = useState([]);
    const [filledAnswer, setFilledAnswer] = useState(" ");
    const [draggedWordValue, setDraggedWordValue] = useState("");
    
    const resetTemplate = () => {
        setFilledAnswer(" ");
        setSubmitDisabled(true);
    }

    useImperativeHandle(ref, () => ({
        resetState() {
            resetTemplate();
        }
    }));
    
    useEffect(()=>{
        resetTemplate();
        setQuestion(String(props.data.questionTitle).split("<FIB>"));
    },[props.data.questionTitle]);
    
    useEffect(()=>{
        props.setSubmittedAnswer(captureAnswer());
    },[filledAnswer]);

    const captureAnswer = () => {
        let childElements = refMainQuestion.current.getElementsByTagName("span");
        let answer = [];
        for(let i=0; i<childElements.length; i++){
            if(childElements[i].classList.contains("droptarget")){
                //Do nothing
            }else{
                answer.push(childElements[i].innerHTML);
            }
        }
        answer = answer.join("");
        return answer;
    }

    const handlerOnVirtualKeyboardEvent = (keyPressed) => {
        // console.log("keyPressed:", keyPressed);
        if(keyPressed == "clear"){
            resetTemplate();
        }else if(keyPressed == "undo"){
            // Do nothing
        }else{
            // Do nothing
        }
    }

    const onClickOption = (_val) => {
        setFilledAnswer(_val);
        setSubmitDisabled(false);
    }

    return (
        <React.Fragment>
            <SubHeader  handlerPreviousQuestion={props.handlerPreviousQuestion}
                        handlerNextQuestion={props.handlerNextQuestion}
                        currentQuestion={props.currentQuestion} 
                        totalQuestions={props.totalQuestions} />
            
            <div className='templateFIB questionArea'>
                <div>
                    <div className='mainQuestion' ref={refMainQuestion}>
                        {question.map((item, index)=> (
                            <React.Fragment key={index}>
                                {(index == question.length -1)?(
                                        <span key={index} dangerouslySetInnerHTML={{__html: item}}></span>
                                    ):(
                                        <React.Fragment>
                                            <span key={index} dangerouslySetInnerHTML={{__html: item}}></span>
                                            <DropTarget targetKey="foo">
                                                <span className="fillInTheBlank">{filledAnswer}</span>
                                            </DropTarget>
                                        </React.Fragment>
                                    )}
                            </React.Fragment>
                        ))}
                    </div>
                    <div className='mainQuestionImage'>
                        {props.data.questionImage && typeof(props.data.questionImage) == "string" && props.data.questionImage != "" ?
                            <img src={CONFIG.gameAssetsDir + CONFIG.gameAssetsURL + props.data.questionImage} />
                        :""}
                    </div>
                    <div className="optionsContainer">
                        {props.data.options.map((option, index) => (
                            <span className="word" key={index} onClick={()=>{onClickOption(option)}}>
                                {option}
                            </span>
                        ))}  
                    </div>
                </div>
                    {(props.data.instruction)?(
                        <div className='instructionArea'>
                            <div className='instructions'>
                                <Translate trKey="instructions" />
                            </div>

                            <div className='instructionsContent'>
                                {props.data.instruction}
                            </div>
                            <div className="quesNum">
                                {MasterData.userData.currentQuesPHP}/{MasterData.userData.totalQuesPHP}
                            </div>
                            <div className={"audioControl" + " " + (props.isMuted?"":"soundSwitch")} onClick={props.handlerMute}></div>
                        </div>
                    ):""}
            </div>
                
                <div className="inputArea">
                    <VirtualKeyboardSubmitAndClear submitDisabled={submitDisabled} keyboardEvent={handlerOnVirtualKeyboardEvent} handlerSubmit={props.handlerSubmit} />
                    <ScoreBoard totalScore={props.totalScore} currentScore={props.userScore} />
                </div>
        </React.Fragment>
    );
});

const TemplateMTF = forwardRef((props, ref) => {
   /* 
    /////References://///
    //https://www.youtube.com/watch?v=FLESHMJ-bI0
    //https://blog.logrocket.com/getting-started-pixijs-react-create-canvas/
    //https://pixijs.download/dev/docs/PIXI.TextStyle.html
    //End of references//

    const [submitDisabled, setSubmitDisabled] = useState(true);
    const refCanvas = useRef();
    const refCanvasContainer = useRef();
    const textStyle = new TextStyle({
        align: "left",
        // fontWeight: "bold",
        // fill: ["#26f7a3", "#01d27e"],
        // stroke: "#eef1f5",
        // strokeThickness: 1,
        // letterSpacing: 5,
        wordWrap: true,
        wordWrapWidth: 350,
        fontSize: 16,
        lineHeight: 25,
        fontFamily: ["-apple-system", "BlinkMacSystemFont", "Segoe UI", "Arial"]
    });
    
    useEffect(()=>{
        const canvas = refCanvas.current;
        canvas.width = 340;//refCanvasContainer.current.clientWidth;
        canvas.height = 488;//refCanvasContainer.current.clientHeight;
    },[]);
    
    const resetTemplate = () => {
        setSubmitDisabled(true);
    }

    useImperativeHandle(ref, () => ({
        resetState() {
            resetTemplate();
        }
    }));

    const handlerOnVirtualKeyboardEvent = (keyPressed) => {
        // console.log("keyPressed:", keyPressed);
        if(keyPressed == "clear"){
            resetTemplate();
        }else if(keyPressed == "undo"){
            // Do nothing
        }else{
            // Do nothing
        }
    }

    return (
        <React.Fragment>
            <SubHeader  handlerPreviousQuestion={props.handlerPreviousQuestion}
                        handlerNextQuestion={props.handlerNextQuestion}
                        currentQuestion={props.currentQuestion} 
                        totalQuestions={props.totalQuestions} />
            
            <div className='templateMTF questionArea'>
                <div ref={refCanvasContainer} className="wh100">
                    <Stage ref={refCanvas} width="350" height="488" dstyle={{width: "inherit", height: "inherit"}} options={{backgroundColor: 0xEEFDEE, antialias: true}}>
                        <Text text={props.data.questionTitle} style={textStyle} y={10}/>
                        <Sprite image={icon} x={10} y={70} width={50} height={50}/>
                        <Sprite image={icon} x={10} y={170} width={50} height={50}/>
                        <Sprite image={icon} x={10} y={270} width={50} height={50}/>
                        <Sprite image={icon} x={10} y={370} width={50} height={50}/>

                        <Sprite image={icon} x={285} y={70} width={50} height={50}/>
                        <Sprite image={icon} x={285} y={170} width={50} height={50}/>
                        <Sprite image={icon} x={285} y={270} width={50} height={50}/>
                        <Sprite image={icon} x={285} y={370} width={50} height={50}/>

                        <Text text={props.data.instruction} style={textStyle} y={465}/>
                    </Stage>
                </div>
            </div>

            <VirtualKeyboardSubmitAndClear submitDisabled={submitDisabled} keyboardEvent={handlerOnVirtualKeyboardEvent} handlerSubmit={props.handlerSubmit} />
        </React.Fragment>
    );
    */
});

export {Template1, Template2, Template3, Template4, TemplateMCQ, TemplateJumbled, TemplateFIB, TemplateMTF};


// var canvasWidth = 180;
// var canvasHeight = 160;
// var canvas = null;
// var bounds = null;
// var ctx = null;
// var hasLoaded = false;

// var startX = 0;
// var startY = 0;
// var mouseX = 0;
// var mouseY = 0;
// var isDrawing = false;
// var existingLines = [];

// function draw() {
//     ctx.fillStyle = "#333333";
//     ctx.fillRect(0,0,canvasWidth,canvasHeight);
    
//     ctx.strokeStyle = "black";
//     ctx.lineWidth = 2;
//     ctx.beginPath();
    
//     for (var i = 0; i < existingLines.length; ++i) {
//         var line = existingLines[i];
//         ctx.moveTo(line.startX,line.startY);
//         ctx.lineTo(line.endX,line.endY);
//     }
    
//     ctx.stroke();
    
//     if (isDrawing) {
//         ctx.strokeStyle = "darkred";
//         ctx.lineWidth = 3;
//         ctx.beginPath();
//         ctx.moveTo(startX,startY);
//         ctx.lineTo(mouseX,mouseY);
//         ctx.stroke();
//     }
// }

// function onmousedown(e) {
//     if (hasLoaded && e.button === 0) {
//         if (!isDrawing) {
//             startX = e.clientX - bounds.left;
//             startY = e.clientY - bounds.top;
            
//             isDrawing = true;
//         }
        
//         draw();
//     }
// }

// function onmouseup(e) {
//     if (hasLoaded && e.button === 0) {
//         if (isDrawing) {
//             existingLines.push({
//                 startX: startX,
//                 startY: startY,
//                 endX: mouseX,
//                 endY: mouseY
//             });
            
//             isDrawing = false;
//         }
        
//         draw();
//     }
// }

// function onmousemove(e) {
//     if (hasLoaded) {
//         mouseX = e.clientX - bounds.left;
//         mouseY = e.clientY - bounds.top;
        
//         if (isDrawing) {
//             draw();
//         }
//     }
// }

// window.onload = function() {
//     canvas = document.getElementById("canvas");
//     canvas.width = canvasWidth;
//     canvas.height = canvasHeight;
//     canvas.onmousedown = onmousedown;
//     canvas.onmouseup = onmouseup;
//     canvas.onmousemove = onmousemove;
    
//     bounds = canvas.getBoundingClientRect();
//     ctx = canvas.getContext("2d");
//     hasLoaded = true;
    
//     draw();
// }