export const MasterData = {
    gameData: {
        bhashaKhel : null,
        bhashaTest : null,
        ganitKhel : null,
        ganitTest : null
    },
    userData: null
}

export const AppStore = {
    initialized: false,
    currentQuestionIndex: 0,
    currentScore: {}
}