import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as Language from './types/Language';

const DefaultLanguage = Language.HI;

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "key one" : "Key one value in English",
            "key 2" : "Key 2 value in English",

            "settings" : "Settings",
            "logout" : "Log out",
            "credits" : "शब्दकोशिश®खेल शिक्षा प्रविधि | स्टार्टअप#DIPP58173",
            "copyright" : "",
            "play" : "Play",
            "Button start": "शब्दकोशिश<br/>हिन्दी शिक्षा खेल",
            "Bhasha Khel": "Bhasha Khel",
            "Ganit Khel": "Ganit Khel",
            "intro heading 1" : "आओ, खेल खेल में<br/>अपनी हिन्दी बेहतर करें।</div>",//"शब्दकोशिश शिक्षा खेल",//"Come lets learn while playing",
            "intro heading 2" : "Come lets learn while playing",//"Interactive learning",
            "previous" : "Previous",
            "next" : "Next",
            "submit" : "Submit",
            "question" : "Question",
            "instructions" : "Instructions",
            "No information" : "Information not available.",
            "game score" : "game score",
            "total score" : "total score",
            "y" : "y",
            "n" : "n",
        }
    },
    hi: {
        translation: {
            "key one" : "Key one value in Hindi",
            "key 2" : "Key 2 value in Hindi",

            "settings" : "समायोजन",
            "logout" : "लॉग आउट",
            "credits" : "शब्दकोशिश®खेल शिक्षा प्रविधि | स्टार्टअप#DIPP58173",
            "copyright" : "",
            "play" : "खेलें",
            "Button start": "शब्दकोशिश<br/>हिन्दी शिक्षा खेल",
            // "Bhasha Khel": "भाषा खेल",
            // "Ganit Khel": "गणित खेल",
            "intro heading 1" : "आओ, खेल खेल में<br/>अपनी हिन्दी बेहतर करें।</div>",//"<div style='color: #024f9d; font-weight: bold; font-size: 28px;'>शब्दकोशिश</div><div style='color: #024f9d; font-size: 22px;'>शिक्षा खेल</div>",//"आओ खेल खेल में शिक्षा को बेहतर बनाए",
            "intro heading 2" : "आओ खेल खेल में शिक्षा को बेहतर बनाएँ",//"इंटरैक्टिव लर्निंग",
            "previous" : "Previous",
            "next" : "Next",
            "submit" : "दर्ज करें",
            "question" : "प्रश्न",
            "instructions" : "कैसे खेलें?",
            "No information" : "जानकारी उपलब्ध नहीं है",
            "game score" : "खेल के अंक",
            "total score" : "कुल अंक",
            "y" : "हाँ",
            "n" : "ना",
        }
    }
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: DefaultLanguage, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        fallbackLng: DefaultLanguage,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export { i18n, DefaultLanguage };