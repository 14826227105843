import React from 'react';
import './Style.scss';
import { useTranslation } from 'react-i18next';

function Translate(props){
    const { t, i18n } = useTranslation();
     
    return(
        <span dangerouslySetInnerHTML={{ __html: t(props.trKey) }}></span>
    );
}

export default Translate;