import React from "react";
import {CONFIG} from '../env';

function AlertReplay(props) {

    const handlerOnClose = () =>{
        window.close();
    }

    return (
        <div className="alertContainer">
            <div className={"backdrop" + " " + "correct congrats"}></div>
            <div className="alertContent">
                <div className={"contentContainer" + " " + props.type} style={{backgroundColor: "#fff0"}}>
                    {/* GIFS are downloaded from
                    https://lottiefiles.com/search?q=Try%20again&category=animations */}

                    <React.Fragment>
                        <div className="wellDone">शाबाश!</div>
                        <img src={CONFIG.gameAssetsDir + "images/emoji-cool.gif"} style={{width: "120px"}} />
                         <span className="message"><b>{props.score}</b> अंक</span>
                        {(props.replayButton == true) &&
                            <div className="button" onClick={props.handlerOnClick}>
                                अगला खेल
                            </div>
                        }
                        {(props.closeButton == true) &&
                            <div className="button" onClick={handlerOnClose}>
                                बंद करें
                            </div>
                        }
                    </React.Fragment>
                </div>
            </div>
        </div>
    );
}

export default AlertReplay;
