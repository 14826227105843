import Translate from "./Translate";

function VirtualKeyboard(props){
    const handlerSubmit = () => {
        if(props.submitDisabled){return}
        props.handlerSubmit();
    }

    return (
        <div className="inputAreaKeys vk1">
            <button className={"clearAnswer" + " " + (props.submitDisabled?"disabled":"")} onClick={()=>{props.keyboardEvent("clear")}}> </button>
            <button className={"submit" + " " + (props.submitDisabled?"disabled":"")} onClick={handlerSubmit}> </button>
            {/* <div className='undo' onClick={()=>{props.keyboardEvent("undo")}}>Undo</div> */}
            <div className='virtualKeyboard'>
                <div className="item1" onClick={()=>{props.keyboardEvent("1")}}>1</div>
                <div className="item2" onClick={()=>{props.keyboardEvent("2")}}>2</div>
                <div className="item3" onClick={()=>{props.keyboardEvent("3")}}>3</div>
                <div className="item4" onClick={()=>{props.keyboardEvent("4")}}>4</div>
                <div className="item5" onClick={()=>{props.keyboardEvent("5")}}>5</div>
                <div className="item6" onClick={()=>{props.keyboardEvent("6")}}>6</div>
                <div className="item7" onClick={()=>{props.keyboardEvent("7")}}>7</div>
                <div className="item8" onClick={()=>{props.keyboardEvent("8")}}>8</div>
                <div className="item9" onClick={()=>{props.keyboardEvent("9")}}>9</div>
                <div className="item0" onClick={()=>{props.keyboardEvent("0")}}>0</div>
                <div className="blank"></div>
                <div className="blank2"></div>
            </div>
        </div>
    );
}

function VirtualKeyboard2(props){
    const handlerSubmit = () => {
        if(props.submitDisabled){return}
        props.handlerSubmit();
    }
    return (
        <div className="inputAreaKeys vk2">
                <button className={"clearAnswer" + " " + (props.submitDisabled?"disabled":"")} onClick={()=>{props.keyboardEvent("clear")}}> </button>
                <button className={"submit" + " " + (props.submitDisabled?"disabled":"")} onClick={handlerSubmit}> </button>
                {/* <div className='undo' onClick={()=>{props.keyboardEvent("undo")}}>Undo</div> */}
            <div className='virtualKeyboard2'>
                <div className="item1 numericButtons"></div>
                <div className="item2 numericButtons"></div>
                <div className="item3 numericButtons"></div>
                <div className="item4 numericButtons"></div>
                <div className="item5 numericButtons"></div>
                <div className="item6 numericButtons"></div>
                <div className="item7 numericButtons"></div>
                <div className="item8 numericButtons"></div>
                <div className="item9 numericButtons"></div>
                <div className="item0 numericButtons"></div>
            </div>
        </div>
    );
}

function VirtualKeyboard3(props){
    const handlerSubmit = () => {
        if(props.submitDisabled){return}
        props.handlerSubmit();
    }
    return (
        <div className="inputAreaKeys vk3">
            <button className={"clearAnswer" + " " + (props.submitDisabled?"disabled":"")} onClick={()=>{props.keyboardEvent("clear")}}> </button>
            <button className={"submit" + " " + (props.submitDisabled?"disabled":"")} onClick={handlerSubmit}> </button>
            {/* <div className='undo' onClick={()=>{props.keyboardEvent("undo")}}>Undo</div> */}
            <div className='virtualKeyboard3'>
                <div className={"item1" + " " + (props.activeButton === 1?"highlight":"")} onClick={()=>{props.keyboardEvent("1")}}>
                    <Translate trKey="y" />
                </div>
                {/* <div className="item2 numericButtons"></div> */}
                <div className="item3 numericButtons"></div>
                {/* <div className="item4 numericButtons"></div> */}
                <div className={"item5" + " " + (props.activeButton === 0?"highlight":"")} onClick={()=>{props.keyboardEvent("0")}}>
                    <Translate trKey="n" />
                </div>
                {/* <div className="item6 numericButtons"></div> */}
                {/* <div className="item7 numericButtons"></div> */}
                <div className="item8 numericButtons"></div>
                {/* <div className="item9 numericButtons"></div> */}
                {/* <div className="item0 numericButtons"></div> */}
            </div>
        </div>
    );
}

function VirtualKeyboardSubmit(props){
    const handlerSubmit = () => {
        if(props.submitDisabled){return}
        props.handlerSubmit();
    }
    return (
        <div className="inputAreaKeys vkS">
                {/* <div className={"clearAnswer" + " " + (props.submitDisabled?"disabled":"")} onClick={()=>{props.keyboardEvent("clear")}}> </div> */}
                <button className={"submit" + " " + (props.submitDisabled?"disabled":"")} onClick={handlerSubmit}> </button>
                {/* <div className='undo' onClick={()=>{props.keyboardEvent("undo")}}>Undo</div> */}
            <div className='virtualKeyboardSubmit'>
                {/* <div className="item1" onClick={()=>{props.keyboardEvent("1")}}>✓</div> */}
                {/* <div className="item2 numericButtons"></div> */}
                {/* <div className="item3 numericButtons"></div> */}
                {/* <div className="item4 numericButtons"></div> */}
                {/* <div className="item5" onClick={()=>{props.keyboardEvent("0")}}>x</div> */}
                {/* <div className="item6 numericButtons"></div> */}
                {/* <div className="item7 numericButtons"></div> */}
                {/* <div className="item8 numericButtons"></div> */}
                {/* <div className="item9 numericButtons"></div> */}
                {/* <div className="item0 numericButtons"></div> */}
            </div>
        </div>
    );
}

function VirtualKeyboardSubmitAndClear(props){
    const handlerSubmit = () => {
        if(props.submitDisabled){return}
        props.handlerSubmit();
    }
    return (
        <div className="inputAreaKeys vkSC">
            <button className={"clearAnswer" + " " + (props.submitDisabled?"disabled":"")} onClick={()=>{props.keyboardEvent("clear")}}> </button>
            <button className={"submit" + " " + (props.submitDisabled?"disabled":"")} onClick={handlerSubmit}> </button>
            {/* <div className='undo' onClick={()=>{props.keyboardEvent("undo")}}>Undo</div> */}
            <div className='virtualKeyboardSubmitAndClear'>
                {/* <div className="item1" onClick={()=>{props.keyboardEvent("1")}}>✓</div> */}
                {/* <div className="item2 numericButtons"></div> */}
                {/* <div className="item3 numericButtons"></div> */}
                {/* <div className="item4 numericButtons"></div> */}
                {/* <div className="item5" onClick={()=>{props.keyboardEvent("0")}}>x</div> */}
                {/* <div className="item6 numericButtons"></div> */}
                {/* <div className="item7 numericButtons"></div> */}
                {/* <div className="item8 numericButtons"></div> */}
                {/* <div className="item9 numericButtons"></div> */}
                {/* <div className="item0 numericButtons"></div> */}
            </div>
        </div>
    );
}

export {VirtualKeyboard, VirtualKeyboard2, VirtualKeyboard3, VirtualKeyboardSubmit, VirtualKeyboardSubmitAndClear};