import axios from "axios";
import {ENV,CONFIG} from "../env";
import { MasterData } from "../store";

function getHeaders(){
    return {
        headers: {
            'Content-Type': 'text/plain'
        }
    }
}

export const getGameData = (_url)=>{
    return axios.get(_url);
}

export const getGameDataFromServer = (_url)=>{
    let requestData = {requestType:"GetData"}
    if(ENV == "dev"){
      requestData.development = true;
      requestData.allQuestions = true;
    }
    return axios.post(_url, requestData);
}

export const saveData = (_url, _totalScore, _userScore)=>{
    let currentScore = 0;
    Object.keys(_userScore).forEach(questionID => {
        currentScore = currentScore + _userScore[questionID];
    });
    let requestData = {
      requestType: "SaveData",
      uid: MasterData.userData.id,
      nid: Object.keys(_userScore),
      score: _totalScore + currentScore,
      currentScore: currentScore
    }

    return axios.post(_url, requestData);
}
