import Translate from "./Translate";

function Footer(){
    return(
        <div className="footer">
            <Translate trKey="credits" />
        </div>
    );
}

export default Footer;