import React from "react";
import {CONFIG} from '../env';

function AlertAlreadyPlayed(props) {

    const handlerOnClose = () =>{
        window.close();
    }

    return (
        <div className="alertContainer" style={{width:"18em"}}>
            <div className={"backdrop" + " " + "correct congrats"}></div>
            <div className="alertContent">
                <div className={"contentContainer" + " " + props.type}>
                    {/* GIFS are downloaded from
                    https://lottiefiles.com/search?q=Try%20again&category=animations */}

                    <React.Fragment>
                        <div className="qPlayed">आप यह पहली खेल चुके हैं</div>
                        <span className="message"><b>{props.score}</b> अंक मिले</span>
                                                
                        <div className="popupBtn" style={{marginTop:"1em"}}>
                            {(props.tryAgainButton == true) &&
                                <div className="button" onClick={props.handlerOnTryAgain} style={{color: "#060"}}>
                                    दोबारा प्रयास करें!
                                </div>
                            }
                            {(props.replayButton == true) &&
                                <div className="button" onClick={props.handlerOnClick} style={{color: "#d2691e"}}>
                                    अगला खेल
                                </div>
                            }
                            {(props.closeButton == true) &&
                                <div className="button" onClick={handlerOnClose}>
                                    बंद करें
                                </div>
                            }
                        </div>
                    </React.Fragment>
                </div>
            </div>
        </div>
    );
}

export default AlertAlreadyPlayed;
